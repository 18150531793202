import Main from "../views/main";
import Login from "../views/login";
import Stats from "../views/stats";
import Detail from "../views/detail";
import Remind from "../views/remind";
const routerArray = [
  { path: "/", name: "Main", component: Main },
  { path: "/stats", name: "Stats", component: Stats },
  { path: "/detail", name: "Detail", component: Detail },
  { path: "/remind", name: "Remind", component: Remind },
  { path: "/login", name: "Login", component: Login, auth: true },
];
export default routerArray;
