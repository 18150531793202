import { useSpring, animated, to } from "react-spring";
import cls from "classnames";
import styles from "./index.module.less";
import { useGesture } from "react-use-gesture";
import { useEffect, useRef } from "react";
// const calcX = (y: number, ly: number) =>
//   -(y - ly - window.innerHeight / 2) / 15;
// const calcY = (x: number, lx: number) => (x - lx - window.innerWidth / 2) / 15;

interface CardProps {
  className?: string;
  children: React.ReactChild;
  onClick?: () => void; // 双击跳转
  disableHover?: boolean;
}
const Card = ({ className, children, onClick, disableHover }: CardProps) => {
  const domTarget = useRef(null);
  useEffect(() => {
    const preventDefault = (e: Event) => e.preventDefault();
    document.addEventListener("gesturestart", preventDefault);
    document.addEventListener("gesturechange", preventDefault);

    return () => {
      document.removeEventListener("gesturestart", preventDefault);
      document.removeEventListener("gesturechange", preventDefault);
    };
  }, []);
  const [{ x, y, rotateX, rotateY, rotateZ, zoom, scale }, api] = useSpring(
    () => ({
      rotateX: 0,
      rotateY: 0,
      rotateZ: 0,
      scale: 1,
      zoom: 0,
      x: 0,
      y: 0,
      config: { mass: 5, tension: 350, friction: 40 },
    })
  );

  useGesture(
    {
      // onPinch: ({ offset: [d, a] }) => api({ zoom: d / 200, rotateZ: a }),
      // onDrag: ({ active, offset: [x, y] }) =>
      //   api({ x, y, rotateX: 0, rotateY: 0, scale: active ? 1 : 1.1 }),
      onMove: ({ xy: [px, py], dragging }) => {
        !disableHover &&
          !dragging &&
          api({
            // rotateX: calcX(py, y.get()),
            // rotateY: calcY(px, x.get()),
            rotateX: 0,
            rotateY: 0,
            scale: 1.1,
          });
      },
      onHover: ({ hovering }) =>
        !disableHover && !hovering && api({ rotateX: 0, rotateY: 0, scale: 1 }),
    },
    { domTarget, eventOptions: { passive: false } }
  );

  return (
    <div className={styles.wrapper} onClick={onClick}>
      <animated.div
        ref={domTarget}
        className={cls(styles.card, className)}
        style={{
          transform: "perspective(600px)",
          x,
          y,
          scale: to([scale, zoom], (s, z) => s + z),
          rotateX,
          rotateY,
          rotateZ,
        }}
      ></animated.div>
      <animated.div className={styles.dropFilter}></animated.div>
      <div className={styles.text}>{children}</div>
    </div>
  );
};

export default Card;
