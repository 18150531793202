import styles from "./index.module.less";
import { Breadcrumb } from "antd";
const Stats = () => {
  return (
    <div className={styles.root}>
      <div className={styles.barWrapper}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item href="/">首页</Breadcrumb.Item>
          <Breadcrumb.Item>统计</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className={styles.wrapper}>
        111
      </div>
    </div>
  );
};
export default Stats;
