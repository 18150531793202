import { Form, Input, Button, message, Badge, Typography, Card } from "antd";
import { post } from "../../utils/request";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import styles from "./index.module.less";
import PmRibbon from "pm-ribbon";

const Login = () => {
  const history = useHistory();
  const colors = [
    "pink",
    "",
    "red",
    "cyan",
    "green",
    "purple",
    "volcano",
    "magenta",
  ];
  const [clientWidth, setClientWidth] = useState<number>(
    window.document.body.clientWidth
  );

  const setWidth = () => {
    setClientWidth(window.document.body.clientWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setWidth);

    return () => {
      window.removeEventListener("resize", setWidth);
    };
  }, []);
  const onFinish = (values: any) => {
    post("/login", values).then(
      (res) => {
        if (res.succeed) {
          sessionStorage.setItem("access_token", "JWT " + res.token);
          history.replace("/");
        } else {
          message.error("用户名或密码错误", 2);
        }
      },
      (res) => {
        message.error("发生错误" + res, 2);
      }
    );
  };
  return (
    <div className={styles.root}>
      <div className={styles.bg}>
        <PmRibbon />
      </div>
      <Card
        style={{
          background: "rgba(255, 255, 255, .5)",
          backdropFilter: "blur(10px)",
          display: "flex",
          flexDirection: "column",
          borderRadius: 16,
        }}
        bordered={false}
      >
        <Card.Grid
          style={{ width: "100%", padding: "50px", borderRadius: "16px" }}
        >
          <Badge.Ribbon
            text="专属"
            color={colors[Math.floor(Math.random() * colors.length)]}
          >
            <div className={styles.card}>
              <Typography.Title>计算机学院</Typography.Title>
              <Typography.Title level={2} style={{ marginTop: 0 }}>
                {"疫 情 防 控 平 台"}
              </Typography.Title>
            </div>
          </Badge.Ribbon>
          <Form
            name="basic"
            style={{ alignSelf: "center" }}
            // labelCol={{ span: 8 }}
            wrapperCol={
              clientWidth < 575 ? { span: 24 } : { span: 16, offset: 4 }
            }
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              // label="工号"
              name="user"
              rules={[{ required: true, message: "请输入你的工号！" }]}
            >
              <Input placeholder="工号" />
            </Form.Item>

            <Form.Item
              // label="密码"
              name="pwd"
              rules={[{ required: true, message: "请输入你的密码！" }]}
            >
              <Input.Password placeholder="密码" />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                提交
              </Button>
            </Form.Item>
          </Form>
        </Card.Grid>
      </Card>
    </div>
  );
};
export default Login;
