import styles from "./index.module.less";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import routers from "../router";
import NotFound from "./notFound";
const App = () => {
  return (
    <div className={styles.root}>
      <Router>
        <Switch>
          {routers.map((item, index) => {
            return (
              <Route
                key={index}
                path={item.path}
                exact
                render={(props) =>
                  item.auth ? (
                    <item.component />
                  ) : sessionStorage.getItem("access_token") ? (
                    <item.component />
                  ) : (
                    <Redirect
                      to={{
                        pathname: "/login",
                        state: { from: props.location },
                      }}
                    />
                  )
                }
              />
            );
          })}
          {/* 所有错误路由跳转页面 */}
          <Route component={NotFound} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
