import Card from "../../components/Card";
import { Button, message, Typography, Breadcrumb } from "antd";
import styles from "./index.module.less";
import { useHistory } from "react-router-dom";
import cls from "classnames";
const Main = () => {
  const history = useHistory();
  return (
    <>
      <div className={styles.root}>
        <div className={styles.barWrapper}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item>首页</Breadcrumb.Item>
          </Breadcrumb>
          <Button
            type="link"
            // size="large"
            onClick={() =>
              message.warn(
                "又有什么想加的功能了🙃？尝试联系催催吧，虽然不一定有用",
                2
              )
            }
          >
            More...
          </Button>
        </div>
        <div className={styles.wrapper}>
          <Card className={styles.card} onClick={() => history.push("/stats")}>
            <Typography.Title level={3}>统计</Typography.Title>
          </Card>
          <Card
            className={cls(styles.card, styles.yellow)}
            onClick={() => history.push("/detail")}
          >
            <Typography.Title level={3}>详情</Typography.Title>
          </Card>
          <Card
            className={cls(styles.card, styles.red)}
            onClick={() => history.push("/remind")}
          >
            <Typography.Title level={3}>提醒</Typography.Title>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Main;
