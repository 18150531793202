import styles from "./index.module.less";
import {
  DatePicker,
  message,
  Spin,
  Table,
  Tag,
  Input,
  Space,
  Button,
  Typography,
  Empty,
  Alert,
  Breadcrumb,
} from "antd";
import moment from "moment";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import { useEffect, useState, useRef, useMemo } from "react";
import { post } from "../../utils/request";
import { useHistory } from "react-router-dom";
import { SortOrder } from "antd/lib/table/interface";

const Detail = () => {
  // 一个隐患：
  // title写死了，改的时候需要改
  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
  };
  const searchInput = useRef(null);
  const columns = [
    {
      title: "姓名",
      dataIndex: "姓名",
      width: 100,
      align: "center" as any,
      onFilter: (value: any, record: any) =>
        record["姓名"].indexOf(value) === 0,
    },
    {
      title: "学工号",
      dataIndex: "学工号",
      width: 100,
      align: "center" as any,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: {
        setSelectedKeys: any;
        selectedKeys: any;
        confirm: any;
        clearFilters: any;
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={"输入你想查找的学工号"}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, "学工号")}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, "学工号")}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              查找
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              重置
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
              }}
            >
              Filter
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value: any, record: any) =>
        record["学工号"]
          ? record["学工号"]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : "",
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (visible) {
          setTimeout(() => (searchInput.current as any).select(), 100);
        }
      },
    },
    {
      title: "手机号码",
      dataIndex: "手机号码",
      align: "center" as any,
      width: 100,
    },
    {
      title: "最近上报时间",
      dataIndex: "最近上报时间",

      width: 150,
      sorter: {
        compare: (a: any, b: any) =>
          a["最近上报时间"] < b["最近上报时间"] ? -1 : 1,
        multiple: 1,
      },
    },
    {
      title: "今日是否在校",
      dataIndex: "今日是否在校",
      width: 100,
    },
    {
      title: "需要注意",
      dataIndex: "需要注意",
      width: 250,
      render: (tags: string[]) => (
        <>
          {tags?.map((tag) => {
            let color = "red";
            if (tag === "其他原因请假未到岗") color = "geekblue";
            else if (tag === "家人近14天入境") color = "orange";
            else if (tag === "发热请假未到岗") color = "volcano";
            return (
              <Tag color={color} key={tag}>
                {tag}
              </Tag>
            );
          })}
        </>
      ),
      sorter: {
        compare: (a: any, b: any) =>
          a["需要注意"].length - b["需要注意"].length,
        multiple: 3,
      },
      defaultSortOrder: "descend" as SortOrder,

      // sortDirections: ["descend"],
    },
  ];
  const [data, setData] = useState<object[]>([]);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [loading, setLoading] = useState(true);
  const [exportLoading, setExportLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [clientWidth, setClientWidth] = useState<number>(
    window.document.body.clientWidth
  );

  const setWidth = () => {
    setClientWidth(window.document.body.clientWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setWidth);

    return () => {
      window.removeEventListener("resize", setWidth);
    };
  }, []);
  const history = useHistory();
  const requestDetail = (date: string) => {
    setLoading(true);
    post("/detail", { date })
      .then(
        (res) => {
          if (res.succeed) {
            let d = res.data;
            let outData: any = [];
            let titles: string[] = d[0];
            let fare_index = titles.findIndex(
              (i) => i.indexOf("今日是否有发热症状") !== -1
            );
            let weidaogang_index = titles.findIndex(
              (i) => i.indexOf("今日是否因发热请假") !== -1
            );
            let qitayuanyin_index = titles.findIndex(
              (i) => i.indexOf("今日是否因发热外的其他原因请假") !== -1
            );
            let dangdigeli_index = titles.findIndex(
              (i) =>
                i.indexOf("今日是否被当地管理部门要求在集中隔离点医学观察") !==
                -1
            );
            let jujiageli_index = titles.findIndex(
              (i) => i.indexOf("今日是否居家隔离观察") !== -1
            );
            let jiankangma_index = titles.findIndex(
              (i) => i.indexOf("今日杭州健康码的颜色") !== -1
            );
            let rujing_index = titles.findIndex(
              (i) => i.indexOf("近14日入境") !== -1
            );
            let name_index = titles.findIndex((i) => i.indexOf("姓名") !== -1);
            let id_index = titles.findIndex((i) => i.indexOf("学工号") !== -1);
            let phone_index = titles.findIndex(
              (i) => i.indexOf("手机号码") !== -1
            );
            let last_time_index = titles.findIndex(
              (i) => i.indexOf("最近上报时间") !== -1
            );
            let at_school_index = titles.findIndex(
              (i) => i.indexOf("今日是否在校") !== -1
            );

            d.forEach((item: any, i: number) => {
              if (i !== 0) {
                let res: any = { key: i, 需要注意: [] };
                res["姓名"] = item[name_index];
                res["学工号"] = item[id_index];
                res["手机号码"] = item[phone_index];
                res["最近上报时间"] = item[last_time_index];
                res["今日是否在校"] = item[at_school_index];
                if (item[fare_index] === "是") {
                  res["需要注意"].push("今日有发热");
                }
                if (item[weidaogang_index] === "是") {
                  res["需要注意"].push("发热请假未到岗");
                }
                if (
                  item[jiankangma_index] &&
                  item[jiankangma_index] !== "绿码"
                ) {
                  res["需要注意"].push("健康码为" + item[jiankangma_index]);
                }
                if (item[qitayuanyin_index] === "是") {
                  res["需要注意"].push("其他原因请假未到岗");
                }
                if (item[dangdigeli_index] === "是") {
                  res["需要注意"].push(
                    "被当地管理部门要求在集中隔离点医学观察"
                  );
                }
                if (item[jujiageli_index] === "是") {
                  res["需要注意"].push("居家隔离观察");
                }
                if (item[rujing_index] === "是") {
                  res["需要注意"].push("家人近14天入境");
                }

                outData.push(res);
              }
            });
            setData(outData);
          }
          // 错误
          else {
            message.error(res.info);
            setData([]);
          }
        },
        (res) => {
          if (res === 401) {
            message.info("您的会话已过期，将自动跳转至登录页面", 1);
            setTimeout(() => {
              history.push("/login");
            }, 1000);
          } else {
            message.info("发生错误，错误代码为：" + res, 2);
          }
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };
  const onChange = (date: moment.Moment | null, dateString: string) => {
    setDate(dateString);
    requestDetail(dateString);
  };
  const download = (blobUrl: string, fileName?: string) => {
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = blobUrl;
    a.download = fileName ?? date;
    a.click();
    a.parentNode?.removeChild(a);
  };
  const export_detail = () => {
    post("/detail/export", { date }, {}, true)
      .then(
        (res) => {
          return res.blob();
        },
        (res) => {
          if (res === 401) {
            message.info("您的会话已过期，将自动跳转至登录页面", 1);
            setTimeout(() => {
              history.push("/login");
            }, 1000);
          } else {
            message.info("发生错误，错误代码为：" + res, 2);
          }
          return Promise.reject("");
        }
      )
      .then((data) => {
        let blobUrl = window.URL.createObjectURL(data);
        download(blobUrl);
      });
  };

  const exportFenZu = () => {
    setExportLoading(true);
    post("/detail/exportFenZu", { date }, {}, true)
      .then(
        (res) => {
          return res.blob();
        },
        (res) => {
          if (res === 401) {
            message.info("您的会话已过期，将自动跳转至登录页面", 1);
            setTimeout(() => {
              history.push("/login");
            }, 1000);
          } else {
            message.info("发生错误，错误代码为：" + res, 2);
          }
          return Promise.reject("");
        }
      )
      .then((data) => {
        let blobUrl = window.URL.createObjectURL(data);
        download(blobUrl, date + "未打卡名单");
      })
      .finally(() => {
        setExportLoading(false);
      });
  };
  const updateNow = () => {
    setUpdateLoading(true);
    post("/detail/update", { date })
      .then((res) => {
        if (res.succeed) {
          message.success(res.info);
          requestDetail(date);
        } else {
          message.error(res.info);
        }
      })
      .finally(() => {
        setUpdateLoading(false);
      });
  };
  useEffect(() => {
    requestDetail(moment().format("YYYY-MM-DD"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const already_num = useMemo(() => {
    let res = 0;
    data.forEach((i: any) => {
      if (i["最近上报时间"] !== "还未上报") res += 1;
    });
    return res;
  }, [data]);

  const zaixiao_num = useMemo(() => {
    let res = 0;
    data.forEach((i: any) => {
      if (i["今日是否在校"] === "是") res += 1;
    });
    return res;
  }, [data]);

  return (
    <div className={styles.root}>
      <div className={styles.barWrapper}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item href="/">首页</Breadcrumb.Item>
          <Breadcrumb.Item>详情</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className={styles.barWrapper}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography.Title level={5} style={{ margin: 0 }}>
            {clientWidth < 500 ? "选择日期：" : "选择要查看的日期："}
          </Typography.Title>
          <DatePicker
            onChange={onChange}
            showToday={true}
            clearIcon={false}
            defaultValue={moment()}
          />
        </div>
        <Typography.Paragraph
          style={{
            margin: clientWidth < 500 ? "0 10px" : "0 20px",
            textAlign: "center",
          }}
        >
          计算机学院当天共{" "}
          <Tag color={"green"} className={styles.smallTag}>
            {data.length}
          </Tag>
          名同学，已打卡{" "}
          <Tag color={"purple"} className={styles.smallTag}>
            {already_num}
          </Tag>
          人，占比
          <Tag
            color={
              Math.round((already_num / (data.length || 1)) * 10000) / 100 > 80
                ? "green"
                : "red"
            }
            className={styles.smallTag}
          >
            {Math.round((already_num / (data.length || 1)) * 10000) / 100}%
          </Tag>
          ，在校
          <Tag color={"blue"} className={styles.smallTag}>
            {zaixiao_num}
          </Tag>
          人
        </Typography.Paragraph>
        {data.length > 0 && (
          <Button
            type="default"
            shape="round"
            icon={<DownloadOutlined />}
            onClick={() => {
              export_detail();
            }}
          >
            导出当日文件
          </Button>
        )}
      </div>
      <div className={styles.barWrapper}>
        <Alert
          message="此网站上当日数据每小时自动更新,也可以手动更新,每日9:30会推送前一天数据"
          showIcon
          type="info"
        />
        <div
          style={{
            display: "flex",
            flexDirection: clientWidth < 500 ? "column" : "row",
          }}
        >
          <Button
            loading={exportLoading}
            shape="round"
            style={{ margin: "0 10px" }}
            onClick={() => exportFenZu()}
          >
            按年级分组导出未打卡名单
          </Button>
          <Button
            loading={updateLoading}
            shape="round"
            style={clientWidth < 500 ? { margin: "20px 10px" } : {}}
            onClick={() => updateNow()}
          >
            手动更新
          </Button>
        </div>
      </div>
      {clientWidth >= 500 && (
        <div className={styles.tableWrapper}>
          {data.length > 0 ? (
            <Table
              columns={columns}
              dataSource={data}
              scroll={{ x: "100%", y: "calc(100vh - 300px)" }}
              pagination={{ position: ["bottomCenter"] }}
            />
          ) : loading ? (
            <Spin />
          ) : (
            <Empty />
          )}
        </div>
      )}
    </div>
  );
};

export default Detail;
