import styles from "./index.module.less";
import {
  Breadcrumb,
  message,
  Button,
  Input,
  Typography,
  List,
  Alert,
  Card,
} from "antd";
import { post } from "../../utils/request";
import { useState } from "react";
import { useLocalStorageState } from "../../utils/useLocalStorage";
import { DeleteOutlined, SendOutlined } from "@ant-design/icons";
const Remind = () => {
  const [loading, setLoading] = useState(false);
  const [sendMessageLoading, setSendMessageLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [whitelist, setWhiteList] = useLocalStorageState("MeSsAge-wHite-liST");
  const [listInputValue, setListInputValue] = useState("");
  const onDailyRemind = () => {
    setLoading(true);
    post("/remind", {})
      .then(
        (res) => {
          if (res.succeed) {
            message.info(res.info, 2);
          } else {
            message.error(res.info, 2);
          }
        },
        (res) => {
          message.error("发生错误", 2);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const onSendMessage = () => {
    setSendMessageLoading(true);
    post("/remind/sendMessage", {
      message: inputValue,
      whiteList: whitelist?.split(","),
    })
      .then(
        (res) => {
          if (res.succeed) {
            message.info(res.info, 2);
          } else {
            message.error(res.info, 2);
          }
        },
        (res) => {
          message.error("发生错误", 2);
        }
      )
      .finally(() => {
        setSendMessageLoading(false);
      });
  };

  const checkPhoneNumber = (phone: string) => {
    let res = true;
    phone.split(",").forEach((p) => {
      p = p.trim();
      if (!(p.length === 11 && /^[1-9][0-9]*$/.test(p))) {
        res = false;
      }
    });
    return res;
  };
  return (
    <div className={styles.root}>
      <div className={styles.barWrapper}>
        <Breadcrumb separator=">">
          <Breadcrumb.Item href="/">首页</Breadcrumb.Item>
          <Breadcrumb.Item>提醒</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          <Button loading={loading} onClick={() => onDailyRemind()}>
            {"浙大钉【一键提醒】"}
          </Button>
          <Alert
            message="兜底设置：一键提醒：每天21:30，短信：配置默认内容，每天17:30"
            showIcon
            type="info"
          />
        </div>
        <div className={styles.bodyWrapper}>
          <div
            className={styles.bodyItem}
            style={{ marginRight: 10, flex: "1 1 30%" }}
          >
            <Card.Grid className={styles.card}>
              <Typography.Title level={4} style={{ margin: "10px 0" }}>
                需要额外添加的号码
              </Typography.Title>
              <Input
                placeholder={"请确保是有效电话号码，输入多个用英文逗号','隔开"}
                onChange={(e) => {
                  setListInputValue(e.target.value);
                }}
                value={listInputValue}
                onPressEnter={() => {
                  if (checkPhoneNumber(listInputValue)) {
                    message.info("添加成功");
                    setListInputValue("");
                    whitelist
                      ? setWhiteList(whitelist + "," + listInputValue)
                      : setWhiteList(listInputValue);
                  } else message.error("输入电话号不合法");
                }}
              ></Input>
              <Typography.Title level={5} style={{ margin: "10px 0" }}>
                已添加的号码列表
              </Typography.Title>
              <List
                dataSource={whitelist ? whitelist.split(",") : []}
                renderItem={(item) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: 5,
                    }}
                  >
                    <div>{item}</div>
                    <DeleteOutlined
                      onClick={() => {
                        setWhiteList(
                          whitelist
                            ?.replace(item + ",", "")
                            .replace("," + item, "")
                            .replace(item, "") ?? ""
                        );
                      }}
                    />
                  </div>
                )}
              />
            </Card.Grid>
          </div>

          <div
            className={styles.bodyItem}
            style={{ marginLeft: 10, flex: "1 1 70%" }}
          >
            <Card.Grid className={styles.card}>
              <Typography.Title level={4}>短信内容</Typography.Title>
              <Input.TextArea
                showCount={true}
                placeholder={
                  "默认短信内容为: 请尽快完成今天的健康打卡，连续三天不打卡将自动取消蓝码[自动发送]"
                }
                value={inputValue}
                onChange={(e) => {
                  setInputValue(e.target.value);
                }}
                autoSize
                allowClear
              ></Input.TextArea>

              <Button
                className={styles.sendButton}
                loading={sendMessageLoading}
                style={{
                  position: "absolute",
                  zIndex: 1,
                  bottom: 20,
                  right: 20,
                }}
                shape="round"
                onClick={() => onSendMessage()}
              >
                <SendOutlined />
                {"发送"}
              </Button>
            </Card.Grid>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Remind;
